import { TPagetreeModel, TPagetreeItem } from "@/common/models/pagetree";

import { cloneDeep } from "lodash";

export interface PagetreeContextItem {
	item: TPagetreeItem;
	childItems: PagetreeContextItem[];
	collapsed?: boolean | null;
	hidden?: boolean | null;
	searchMatched?: boolean | null;
	filterMatched?: boolean | null;
}

export class PagetreeContext {
	pagetree: TPagetreeModel;
	childItems: PagetreeContextItem[] | null;
	currentItem: TPagetreeItem | null;
	loading?: boolean | null;

	constructor(srcPagetree: TPagetreeModel) {
		this.pagetree = cloneDeep(srcPagetree);
		this.childItems = PagetreeContext.wrapItems(srcPagetree.childItems);
		this.currentItem = null;
	}

	public searchItems(str: string): boolean {
		const res = PagetreeContext.searchPagetreeItems(str.toLowerCase(), this.childItems);
		PagetreeContext.hideItems(this.childItems);
		return res;
	}

	public clearSearch(): void {
		PagetreeContext.clearPagetreeItemsSearch(this.childItems);
		PagetreeContext.hideItems(this.childItems);
	}

	public applyTagFilter(tags: string[], keepItemsWithoutTags: boolean): void {
		PagetreeContext.applyTagFilter(tags, keepItemsWithoutTags, this.childItems);
		PagetreeContext.hideItems(this.childItems);
	}

	public clearTagFilter(): void {
		PagetreeContext.clearTagFilter(this.childItems);
		PagetreeContext.hideItems(this.childItems);
	}

	public init(initialItem?: TPagetreeItem): void {
		PagetreeContext.clearPagetreeItemsSearch(this.childItems);
		this.currentItem = initialItem ?? null;
		PagetreeContext.openInitialPagetreeItem(this.currentItem?.key, this.childItems);
	}

	private static wrapItems(items: TPagetreeItem[]): PagetreeContextItem[] {
		if (!items || items.length < 1) {
			return [];
		}
		return items.map((item) => <PagetreeContextItem>{ item: item, childItems: PagetreeContext.wrapItems(item.childItems) });
	}

	private static hideItems(contextItems: PagetreeContextItem[] | null): boolean {
		if (!contextItems || !contextItems.length) {
			return true;
		}

		let allItemsHidden = true;
		for (const contextItem of contextItems) {
			contextItem.hidden = false;
			if (contextItem.searchMatched === false || contextItem.filterMatched === false) { //hide if filter or search didn't matched
				contextItem.hidden = true;
			} else if (contextItem.childItems.length > 0) { //Hide if all children hidden
				const allChildrenHidden = PagetreeContext.hideItems(contextItem.childItems);
				if (allChildrenHidden) {
					contextItem.hidden = true;
				}
			}

			if (!contextItem.hidden) {
				allItemsHidden = false;
			}
		}
		return allItemsHidden;
	}

	private static searchPagetreeItems(str: string, contextItems: PagetreeContextItem[] | null): boolean {
		if (!contextItems || !contextItems?.length) {
			return false;
		}
		let matchFound = false;
		for (const contextItem of contextItems) {
			let itemMatched = (((contextItem.item.primaryText?.toLowerCase()?.indexOf(str) ?? -1) > -1) || ((contextItem.item.secondaryText?.toLowerCase()?.indexOf(str) ?? -1) > -1));
			if (contextItem.childItems.length > 0) {
				const childMatched = PagetreeContext.searchPagetreeItems(str, contextItem.childItems);
				if (childMatched) {
					itemMatched = true;
				}
			}
			if (itemMatched) {
				matchFound = true;
				contextItem.searchMatched = true;
			} else {
				contextItem.searchMatched = false;
			}
		}
		return matchFound;
	}

	private static clearPagetreeItemsSearch(contextItems: PagetreeContextItem[] | null): void {
		if (!contextItems) {
			return;
		}
		for (const item of contextItems) {
			if (item.childItems.length > 0) {
				PagetreeContext.clearPagetreeItemsSearch(item.childItems);
			}
			item.searchMatched = null;
		}
	}

	/**
	 * If item doesn't have tag specified in tags array, item will be hidden.
	 * Filter also applied to all descendants
	 * @param {any} tags
	 * @param {any} keepItemsWithoutTags
	 * @param {any} contextItems
	 */
	private static applyTagFilter(tags: string[], keepItemsWithoutTags: boolean, contextItems: PagetreeContextItem[] | null): void {
		if (!contextItems) {
			return;
		} 
		for (const contextItem of contextItems) {
			let itemMatched = false;

			if ((contextItem.item.tags?.length ?? 0) === 0) {
				itemMatched = keepItemsWithoutTags;
			} else {
				itemMatched = contextItem.item.tags.some((element) => tags.includes(element)) ?? false;
			}

			if (contextItem.childItems.length > 0) {
				PagetreeContext.applyTagFilter(tags, keepItemsWithoutTags, contextItem.childItems);
			}

			contextItem.filterMatched = itemMatched;
		}
	}

	/**
	 * Erases filter from all items and their descendants
	 * @param {any} contextItems
	 */
	private static clearTagFilter(contextItems: PagetreeContextItem[] | null): void {
		if (!contextItems) {
			return;
		}

		for (const contextItem of contextItems) {
			if (contextItem.childItems.length > 0) {
				PagetreeContext.clearPagetreeItemsSearch(contextItem.childItems);
			}
			contextItem.filterMatched = null;
		}
	}

	private static openInitialPagetreeItem(key: string | undefined, contextItems: PagetreeContextItem[] | null): boolean {
		if (!contextItems) {
			return false;
		}
		let matchFound = false;
		for (const contextItem of contextItems) {
			let itemMatched = contextItem.item.key === key;
			if (!itemMatched && contextItem.childItems.length > 0) {
				const childMatched = PagetreeContext.openInitialPagetreeItem(key, contextItem.childItems);
				if (childMatched) {
					itemMatched = true;
				}
			}
			if (itemMatched) {
				matchFound = true;
				contextItem.collapsed = false;
			} else {
				contextItem.collapsed = true;
			}
		}
		return matchFound;
	}
}

function createPagetreeContext(source: TPagetreeModel, initialItem?: TPagetreeItem): PagetreeContext {
	const pagetreeContext = new PagetreeContext(source);
	pagetreeContext.init(initialItem);
	return pagetreeContext;
}

export { createPagetreeContext };