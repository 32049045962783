
import CreateEventBus from "mitt";
import PagetreeSearch from "./PagetreeSearch.vue";
import PagetreeItem from "./PagetreeItem.vue";
import LoadingCircle from "@/common/components/loading-circle/LoadingCircle.vue";
import { isEmpty, debounce } from "lodash";
import { Vue, Options } from "vue-class-component";
import { Provide, Prop, Watch } from "vue-property-decorator";
import { createPagetreeContext, PagetreeContext } from "./pagetree-context";
import { ITagFilter } from "./models/tag-filter";
import { TPagetreeItem, TPagetreeModel } from "@/common/models/pagetree";

@Options<PagetreeComponent>({
	components: {
		PagetreeItem,
		PagetreeSearch,
		LoadingCircle
	},
	emits: ["update:selectedItem"]
})
export default class PagetreeComponent extends Vue {
	@Provide() public pagetreeContextRef = { value: {} as PagetreeContext };
	@Provide() public pagetreeEventBus = CreateEventBus<PagetreeEventBusEvents>();

	@Prop({ default: null }) readonly pagetree: TPagetreeModel | null = null;
	@Prop({ default: null }) readonly tagFilter: ITagFilter | null = null;
	@Prop({ default: false }) readonly isLoading!: boolean;
	@Prop({ default: null }) readonly selectedItem: TPagetreeItem | null = null;
	@Prop({ default: "" }) readonly emptyText: string = ""
	public isSearching: boolean = false;
	public searchText: string = "";
	public collapseToggled = false;

	get empty(): boolean {
		return isEmpty(this.pagetreeContextRef.value);
	}

	public searchDebounced = debounce(this.search, 500);
	public search(): void {
		if (this.empty) {
			return;
		}

		if (this.searchText.length > 1) {
			this.isSearching = true;
			setTimeout(() => {
				this.pagetreeContextRef.value.searchItems(this.searchText);
				this.isSearching = false;
			});
		} else if (this.searchText.length === 0) {
			this.isSearching = true;
			setTimeout(() => {
				this.pagetreeContextRef.value.clearSearch();
				this.isSearching = false;
			});
		}
	}

	@Watch("selectedItem")
	public onSelectedItemhange(selectedItem: TPagetreeItem): void {
		this.pagetreeContextRef.value.currentItem = selectedItem;
	}

	@Watch("pagetree", { immediate: true })
	public onPagetreeChange(pagetree: TPagetreeModel): void {
		if (!pagetree) {
			return;
		}
		this.pagetreeContextRef.value = createPagetreeContext(
			pagetree,
			this.selectedItem ?? undefined
		);
		this.searchText = "";
	}

	@Watch("tagFilter")
	public onTagFilterChange(tagFilter: ITagFilter): void {
		if (!this.pagetreeContextRef?.value) {
			return;
		}

		if (!tagFilter || tagFilter.allTagsSelected) {
			this.pagetreeContextRef.value?.clearTagFilter();
		} else {
			this.pagetreeContextRef.value?.applyTagFilter(tagFilter.tags, false);
		}
	}

	created(): void {
		this.pagetreeEventBus.on("itemSelected", (item: TPagetreeItem | null) => {
			this.$emit("update:selectedItem", item);
		});
		document.addEventListener("togglepagetree", (event: any) => {
			this.collapseToggled = event.detail;
		});
	}
}
export type PagetreeEventBusEvents = {
	itemSelected: TPagetreeItem | null;
};
