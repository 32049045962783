import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eddf87a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagetree-content pagetree" }
const _hoisted_2 = {
  key: 0,
  class: "loading-circle-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "pagetree-scrollable"
}
const _hoisted_5 = { class: "pagetree-root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagetree_search = _resolveComponent("pagetree-search")!
  const _component_loading_circle = _resolveComponent("loading-circle")!
  const _component_pagetree_item = _resolveComponent("pagetree-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pagetree_search, {
      modelValue: _ctx.searchText,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchDebounced()))
      ]
    }, null, 8, ["modelValue"]),
    (_ctx.isLoading || _ctx.isSearching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_loading_circle)
        ]))
      : (_ctx.empty)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.emptyText), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagetreeContextRef.value.childItems, (i) => {
                return (_openBlock(), _createBlock(_component_pagetree_item, {
                  itemContext: i,
                  key: i.key
                }, null, 8, ["itemContext"]))
              }), 128))
            ])
          ]))
  ]))
}