<template>
	<li
		:class="{ 'pagetree-current': selected }"
		v-if="itemContext.hidden !== true"
		:data-key="item.key"
	>
		<div class="d-flex" :data-color="item.color">
			<span
				v-if="itemContext.childItems && itemContext.childItems.length > 0"
				class="pagetree-arrow"
				v-bind:class="{ 'pagetree-arrow-down': !collapsed }"
				v-on:click="toggleCollapse"
			></span>
			<span
				v-if="item.icon"
				class="pagetree-icon"
				:data-icon="item.icon"
			></span>

			<div class="item-text">
				<a
					class="pagetree-service-link"
					v-on:click="select"
					href="javascript:;"
					>{{ item.primaryText }}</a
				>
				<span
					v-if="item.secondaryText !== null"
					class="pagetree-service-service_code"
					>{{ item.secondaryText }}</span
				>
			</div>
		</div>
		<ul
			class="nested active"
			v-if="
				itemContext.childItems &&
				itemContext.childItems.length > 0 &&
				(!collapsed || itemContext.searchMatched)
			"
		>
			<pagetree-item
				v-for="i of itemContext.childItems"
				:itemContext="i"
				v-bind:key="i.key"
			></pagetree-item>
		</ul>
	</li>
</template>

<script>
export default {
	name: "pagetree-item",
	props: { itemContext: Object },
	inject: ["pagetreeContextRef", "pagetreeEventBus"],
	data: function () {
		return {
			collapsed: this.itemContext.collapsed
		};
	},
	computed: {
		item: function () {
			return this.itemContext.item;
		},
		pagetreeContext: function () {
			return this.pagetreeContextRef.value;
		},
		selected: function () {
			return this.pagetreeContext.currentItem?.key === this.item.key;
		}
	},
	methods: {
		toggleCollapse: function () {
			if (this.collapsed) {
				this.collapsed = false;
			} else {
				this.collapsed = true;
			}
		},
		select: function () {
			this.pagetreeEventBus.emit("itemSelected", this.itemContext.item);
		}
	}
};
</script>

<style lang="scss" scoped>
.pagetree-arrow {
	cursor: pointer;
	user-select: none;
	vertical-align: top;
	padding-left: 3px;
}

.pagetree-arrow::before {
	content: "\276F";
	color: black;
	display: inline-block;
	margin-right: 6px;
}

.pagetree-arrow-down::before {
	transform: rotate(90deg);
}

.pagetree-icon {
	margin-right: 5px;
	vertical-align: top;

	&::before {
		content: " ";
		display: inline-block;
		margin-top: 0.1em;
	}
	&[data-icon="default"]::before {
		background: url("/_/Common/images/icons/26_service.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="service"]::before {
		background: url("/_/Common/images/icons/26_service.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="service-modified"]::before {
		background: url("/_/Common/images/icons/26_service_warning.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="service-new"]::before {
		background: url("/_/Common/images/icons/26_service_new.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="service-removed"]::before {
		background: url("/_/Common/images/icons/26_service_removed.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="file"]::before {
		background: url("/_/Common/images/icons/26_file.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="file-modified"]::before {
		background: url("/_/Common/images/icons/26_file_warning.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="file-new"]::before {
		background: url("/_/Common/images/icons/26_file_new.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="file-removed"]::before {
		background: url("/_/Common/images/icons/26_file_removed.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="folder"]::before {
		background: url("/_/Common/images/icons/26_folder.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="folder-modified"]::before {
		background: url("/_/Common/images/icons/26_folder_warning.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="folder-new"]::before {
		background: url("/_/Common/images/icons/26_folder_new.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="folder-removed"]::before {
		background: url("/_/Common/images/icons/26_folder_removed.svg");
		background-size: cover;
		height: 1.4em;
		width: 1.4em;
	}
	&[data-icon="point"]::before {
		content: "\2022";
		color: black;
	}
}
*[data-color="yellow"] {
    filter: hue-rotate(216deg) saturate(1.4) brightness(1.6);
}
.item-text {
	display: inline-block;
}
.pagetree-root ul,
.pagetree-root {
	list-style-type: none;
}
.pagetree-current {
	background-color: #e5f5fb;
}
.pagetree-service-service_code {
	display: block;
	color: gray;
	margin-top: -0.1em;
	font-size: 1em;
}
.pagetree-service-link {
	color: #025f75;
	font-size: 1.1em;
	font-weight: 450;
	vertical-align: top;
}
</style>