
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class SidebarWrapperComponent extends Vue {
	public sidebarCollapseToggled: boolean = false;
	@Prop({ default: true }) public showSidebar!: boolean;

	sidebarCollapseToggle(): void {
		this.sidebarCollapseToggled = !this.sidebarCollapseToggled;
	}
}
